import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import { FaAngleDoubleDown, FaAngleDoubleRight } from 'react-icons/fa';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Location from '../components/Location';
import ContactSection from '../components/ContactSection';
import AwardsSection from '../components/AwardsSection';
import SubscribeSection from '../components/SubscribeSection';

const ServicesPage = () => {
  const {
    site,
    servicesHero,
    multiResidential,
    customHomes,
    commercial,
    designConstruct,
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          phone
          phoneFormatted
        }
      }
      servicesHero: file(relativePath: { regex: "/services_new.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      multiResidential: file(
        sourceInstanceName: { eq: "hero-images" }
        relativePath: { eq: "foreshore-ave.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      customHomes: file(
        sourceInstanceName: { eq: "hero-images" }
        relativePath: { eq: "matthew-flinders.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      commercial: file(
        sourceInstanceName: { eq: "hero-images" }
        relativePath: { eq: "discovery-early-learning.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      designConstruct: file(
        sourceInstanceName: { eq: "hero-images" }
        relativePath: { eq: "ascot-villas.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const services = [
    {
      title: 'Multi Residential and Community Facilities',
      image: multiResidential.childImageSharp.fluid,
      cta: {
        label: 'More Multi/Community Projects',
        slug: '/projects/foreshore-ave/',
        filter: 'Multi/Community',
      },
      copy: `<p>Multi-Residential Projects and Community Facilities Creating shared living environments is what we do best.</p>
      <p>Developing well-designed, functional and affordable low to medium density housing. Buildings that are sensitive to the needs of the community. And give great consideration to who will live in them.</p>
      <p>Morr Homes and Projects specialise in the design and construction of:</p>
      <ul>
        <li>Residential units</li>
        <li>Villas</li>
        <li>Townhouses</li>
        <li>Independent living</li>
        <li>Retirement villages</li>
      </ul>
      <p>We understand every build has a unique set of criteria, which is why our experienced project management team adopts a collaborative approach. Enhancing our ability to deliver our client with a quality outcome. That meets your expectation and secures a positive return on investment.</p>`,
    },
    {
      title: 'Custom Homes',
      image: customHomes.childImageSharp.fluid,
      cta: {
        label: 'View our custom homes',
        slug: '/projects/matthew-flinders/',
        filter: 'Custom home',
      },
      copy: `<p>Looking to build your dream home? The opportunity of bringing your vision to life is an exciting time.</p>
      <p>But having the right team for a smooth journey is essential. Whether you bring your own plans, or we connect you with an architect. Our goal is ensuring your building experience is as satisfying as the result.</p>
      <p>Using a disciplined building system and planning process is our strength. And respecting a collaborative approach. There will be many professionals involved in building your custom home: architect, engineer, builder, landscaper, interior designer. By staying connected from concept to completion, a cohesive team achieves excellent results.</p>
      <p>Building one-of-a-kind homes are special. From the initial stages of site inspection to the handing over of keys. We consider the construction of a custom home to be a privilege. A unique, creative experience we love sharing with clients.</p>
      `,
    },
    {
      title: 'Commercial Building Projects',
      image: commercial.childImageSharp.fluid,
      cta: {
        label: 'View our commercial projects',
        slug: '/projects/discovery-early-learning/',
        filter: 'Commercial',
      },
      copy: `<p>Multi-Residential Projects and Community Facilities Creating shared living environments is what we do best.</p><p> Developing well-designed, functional and affordable low to medium density housing. Buildings that are sensitive to the needs of the community. And give great consideration to who will live in them.</p><p>Morr Homes and Projects specialise in the design and construction of:</p><ul className="bullets"><li>Residential units</li><li>Villas</li><li>Townhouses</li><li>Independent living</li><li>Retirement villages</li></ul><p> We understand every build has a unique set of criteria, which is why our experienced project management team adopts a collaborative approach. Enhancing our ability to deliver our client with a quality outcome. That meets your expectation and secures a positive return on investment.</p>`,
    },
    {
      title: 'Design and Construct',
      image: designConstruct.childImageSharp.fluid,
      cta: {
        label: 'More design & construct',
        slug: '/projects/ascot-villas/',
        filter: 'Design & Construct',
      },
      copy: `<p>As a design &amp; construct builder, we offer a complete home building package. Our design and construct method is the perfect building solution for many clients. With one point of contact from start to finish. We streamline the building process to include quality home design. And reduce stress by getting you into your new home faster! Plus, the flexibility to tailor home designs to suit your budget and needs.</p><p>Here are five key benefits in choosing Design and Construct:</p><ul className="bullets"><li><strong><em>Accountability</em></strong> - design, cost, quality, scheduling. The buck stops with us!</li><li><strong><em>Buildability</em></strong> - we’ve done due diligence to guarantee quality outcomes.</li><li><strong><em>Cost Savings</em></strong> - known building costs are firm, reducing cost variations.</li><li><strong><em>Time Savings</em></strong> - our efficient processes reduce construction lead times.</li><li><strong><em>Teamwork</em></strong> - a positive hub of team members working toward a common goal.</li></ul>`,
    },
  ];

  return (
    <Layout>
      <SEO title="Services" />
      <div className="relative">
        <Image
          style={{ height: `50vh`, minHeight: `22rem` }}
          fluid={servicesHero.childImageSharp.fluid}
          alt="Aerial shot looking down at rooftops"
        />
        <div className="absolute inset-0 flex items-end justify-center max-w-3xl p-4 mx-auto">
          <AnchorLink
            className="flex items-center justify-center px-2 py-1 leading-none bg-white"
            href="#services"
            offset="56"
          >
            <FaAngleDoubleDown className="text-green-500" />
          </AnchorLink>
        </div>
      </div>
      <section
        id="services"
        className="flex flex-col p-4 my-8 overflow-hidden md:mb-16 sm:px-12 lg:px-24"
      >
        <div className="">
          <div key={services[0].cta.slug} className="flex flex-row flex-wrap">
            <div className="w-full mb-6 text-gray-600 xl:w-1/2">
              <Link
                to={`/projects?filter=${encodeURIComponent(
                  services[0].cta.filter.toLowerCase()
                )}`}
                className="block bg-black xl:max-w-xl xl:mx-auto xl:mt-12"
              >
                <Image fluid={services[0].image} />
              </Link>
            </div>
            <div className="w-full xl:w-1/2 xl:pl-6">
              <h2 className="w-full my-8 text-4xl font-bold leading-none text-slategreen">
                {services[0].title}
              </h2>
              <div
                dangerouslySetInnerHTML={{ __html: services[0].copy }}
                className="prose"
              />
              <Link
                to={`/projects?filter=${encodeURIComponent(
                  services[0].cta.filter.toLowerCase()
                )}`}
                className="inline-block w-full max-w-sm mt-6 mb-12 font-bold uppercase"
              >
                <span>{services[0].cta.label}</span>
                <FaAngleDoubleRight className="inline-block ml-1 -mt-2 text-xs" />
              </Link>
            </div>
          </div>

          <div key={services[1].cta.slug} className="flex flex-row flex-wrap">
            <div className="w-full xl:w-1/2 xl:pl-6 xl:max-w-xl xl:mx-auto">
              <h2 className="w-full my-8 text-4xl font-bold leading-none text-slategreen">
                {services[1].title}
              </h2>
              <div
                dangerouslySetInnerHTML={{ __html: services[1].copy }}
                className="prose"
              />
              <Link
                to={`/projects?filter=${encodeURIComponent(
                  services[1].cta.filter.toLowerCase()
                )}`}
                className="inline-block w-full max-w-sm mt-6 mb-12 font-bold uppercase"
              >
                <span>{services[1].cta.label}</span>
                <FaAngleDoubleRight className="inline-block ml-1 -mt-2 text-xs" />
              </Link>
            </div>
            <div className="order-first w-full mb-6 text-gray-600 xl:w-1/2 xl:order-last">
              <Link
                to={`/projects?filter=${encodeURIComponent(
                  services[1].cta.filter.toLowerCase()
                )}`}
                className="block bg-black xl:max-w-xl xl:mt-12"
              >
                <Image fluid={services[1].image} />
              </Link>
            </div>
          </div>

          <div key={services[2].cta.slug} className="flex flex-row flex-wrap">
            <div className="w-full mb-6 text-gray-600 xl:w-1/2">
              <Link
                to={`/projects?filter=${encodeURIComponent(
                  services[2].cta.filter.toLowerCase()
                )}`}
                className="block bg-black xl:max-w-xl xl:mx-auto xl:mt-12"
              >
                <Image fluid={services[2].image} />
              </Link>
            </div>
            <div className="w-full xl:w-1/2 xl:pl-6">
              <h2 className="w-full my-8 text-4xl font-bold leading-none text-slategreen">
                {services[2].title}
              </h2>
              <div
                dangerouslySetInnerHTML={{ __html: services[2].copy }}
                className="prose"
              />
              <Link
                to={`/projects?filter=${encodeURIComponent(
                  services[2].cta.filter.toLowerCase()
                )}`}
                className="inline-block w-full max-w-sm mt-6 mb-12 font-bold uppercase"
              >
                <span>{services[2].cta.label}</span>
                <FaAngleDoubleRight className="inline-block ml-1 -mt-2 text-xs" />
              </Link>
            </div>
          </div>
          <div key={services[3].cta.slug} className="flex flex-row flex-wrap">
            <div className="w-full xl:w-1/2 xl:pl-6 xl:max-w-xl xl:mx-auto">
              <h2 className="w-full my-8 text-4xl font-bold leading-none text-slategreen">
                {services[3].title}
              </h2>
              <div
                dangerouslySetInnerHTML={{ __html: services[3].copy }}
                className="prose"
              />
              <Link
                to={`/projects?filter=${encodeURIComponent(
                  services[3].cta.filter.toLowerCase()
                )}`}
                className="inline-block w-full max-w-sm mt-6 font-bold uppercase"
              >
                <span>{services[3].cta.label}</span>
                <FaAngleDoubleRight className="inline-block ml-1 -mt-2 text-xs" />
              </Link>
            </div>
            <div className="order-first w-full mb-6 text-gray-600 xl:w-1/2 xl:order-last">
              <Link
                to={`/projects?filter=${encodeURIComponent(
                  services[3].cta.filter.toLowerCase()
                )}`}
                className="block bg-black xl:max-w-xl xl:mt-12"
              >
                <Image fluid={services[3].image} />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <article className="overflow-hidden">
        <div className="flex col-span-2 px-4 py-8 bg-slategreen full-bleed ">
          <span className="max-w-5xl mx-auto font-bold leading-none text-center text-white md:text-4xl">
            Let's Work Together{' '}
            <button
              type="button"
              className="px-8 py-2 ml-6 text-sm font-semibold leading-none tracking-wider text-white transform bg-green-500 cursor-pointer lg:-translate-y-2 hover:bg-green-400"
            >
              <a href={`tel:${site.siteMetadata.phone}`}>Call (02) 6583 9618</a>
            </button>
          </span>
        </div>
      </article>

      <ContactSection />
      <SubscribeSection />
    </Layout>
  );
};

export default ServicesPage;
